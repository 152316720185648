import React from 'react';
// import { fetchDataControl } from '../../../../actions/controlActions';
// import Loader from "../../../../__utils__/Spinner";

import './Control.scss';

const Control = () => {
    return (
        <div className="main-content">
            <div className="control">CONTROL</div>
        </div>
    );
};

export default Control;
