import React from 'react';

import './Settings.scss';

const Settings: React.FC = () => {
    return (
        <div className="main-content">
            <div className="settings">SETTINGS</div>
        </div>
    );
};

export default Settings;
