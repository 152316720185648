// GLOBAL SETTINGS
// const site = 'http://172.16.3.101:8080';
// const site = 'http://team01.vpn.carddex:9999/pc/'
// const site = 'http://team.carddex.ru:9999/pc/';      // DEVELOPMENT SERVER
// const site = 'https://team.carddex.ru/api/pc/'; // PRODUCTIONS SERVER
const site = 'https://team.carddex.ru/api/ppc/'; // PRODUCTIONS SERVER

// const site = 'http://172.16.3.101:8080';
// const site = "https://embedded.carddex.konstructor.online/api/pc/"
export default site;
